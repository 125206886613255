function welcome() {
  console.log(
    `
%cAARRANGE%c

%cWARNING%chttps://en.wikipedia.org/wiki/Self-XSS
Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. Do not enter or paste code that you do not understand.
`,
    'font-size: 22px; font-weight: 900; font-family: monospace;',
    'font-size: initial; font-weight: initial; font-family: initial;',

    'font-size: 16px; font-weight: 600; background: red; color: white; border-radius: 4px; padding: 2px 4px; float: left; display: inline; margin-right: 8px; margin-bottom: 4px;',
    'font-size: 16px;'
  )
}

export default welcome
